import React, { useState } from 'react';
import '../style/TransactionPopup.css';
import categoryService from '../../../constants/constants';

const TransactionPopup = ({ transaction, categories, onClose, onSave, onDelete }) => {
  const [category, setCategory] = useState(categoryService.getCategoryByServiceName(transaction.category));
  const [amount, setAmount] = useState(transaction.denomination.double);

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  function convertToUnitAndNanos(amount) {
    const unit = Math.floor(amount); // Целая часть (рубли)
    const nanos = Math.round((amount - unit) * 100); // Дробная часть (копейки)
  
    return {
      unit: unit,
      nanos: nanos,
      double: parseFloat(amount)
    };
  }

  const handleSave = () => {
    // Вызов функции сохранения
    onSave({
      category,
      denomination: convertToUnitAndNanos(amount)
    });
    onClose();
  };

  const handleDelete = () =>{
    onDelete()
    onClose();
  }

  return (
    <div className="transaction-popup">
      <div className="popup-content-change">
        <div className="popup-header">
          <h3>Редактировать транзакцию</h3>
          <button className="popup-close-button" onClick={onClose}>×</button>
        </div>
        <div className="popup-body">
          <label htmlFor="category">Категория:</label>
          <select
            id="category"
            value={category}
            onChange={handleCategoryChange}
          >
            {categories.map((cat) => (
              <option key={cat} value={cat}>
                {cat}
              </option>
            ))}
          </select>

          <label htmlFor="amount">Сумма:</label>
          <input
            type="number"
            id="amount"
            value={amount}
            onChange={handleAmountChange}
          />
        </div>
        <div className="popup-buttons">
          <button className="save-button" onClick={handleSave}>
            Сохранить
          </button>
          <button className="cancel-button" onClick={onClose}>
            Отмена
          </button>
          <button className="delete-button" onClick={handleDelete}>
            Удалить
          </button>
        </div>
      </div>
    </div>
  );
};

export default TransactionPopup;
