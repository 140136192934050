import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Header from './main/header/header';
import Main from './main/main';
import AccountInfo from './main/account/AccountInfo';
import { DayInfoProvider } from './main/dashboard/DayInfoWithChartContext';
import { AccountsProvider } from './main/account/AccountsContext';
import { CalendarProvider } from './calendar/CalendarContext';
import './styles.css'
import Calendar from './calendar/Calendar';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <DayInfoProvider>
    <AccountsProvider>
      <CalendarProvider>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Main />} />
            <Route path="/calendar"  element={<Calendar/>}/> {/* Добавьте компонент для маршрута */}
          <Route path="/job"  /> {/* Добавьте компонент для маршрута */}
          <Route path="/account/:id" element={<AccountInfo />} /> {/* Добавляем маршрут для аккаунта */}
        </Routes>
      </Router>
      </CalendarProvider>
    </AccountsProvider>
  </DayInfoProvider>
);