import React, { useState, useEffect, useContext } from 'react';
import './Calendar.css'; // Стили для календаря
import { CalendarContext } from './CalendarContext';
import DayInfo from './DayInfo';

const Calendar = () => {
  const [selectedDay, setSelectedDay] = useState(null); // Состояние для выбранного дня
  const [groupedByMonth, setGroupedByMonth] = useState({});
  const dayData = useContext(CalendarContext);
  const daysOfWeek = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

  // Функция для группировки дней по месяцам и сортировки по дням недели
  useEffect(() => {
    const grouped = dayData.reduce((acc, day) => {
      const date = new Date(day.dayOfYear);
      const month = date.toLocaleString('default', { month: 'long', year: 'numeric' });

      if (!acc[month]) {
        acc[month] = [];
      }

      acc[month].push(day);
      return acc;
    }, {});

    // Упорядочим по дням недели
    Object.keys(grouped).forEach((month) => {
      const sortedDays = sortDaysByWeek(grouped[month]);
      grouped[month] = sortedDays;
    });

    setGroupedByMonth(grouped);
  }, [dayData]);

  // Функция сортировки дней по неделям, начиная с понедельника
  const sortDaysByWeek = (days) => {
    const sortedDays = [];
    let week = Array(7).fill(null); // Пустая неделя (начало с понедельника)

    days.forEach((day) => {
      const date = new Date(day.dayOfYear);
      const dayOfWeek = (date.getDay() + 6) % 7; // Преобразуем воскресенье (0) в конец недели (6)
      week[dayOfWeek] = day;

      if (dayOfWeek === 6) {
        sortedDays.push(week);
        week = Array(7).fill(null);
      }
    });

    // Добавляем последнюю неделю, если есть остаток
    if (week.some((day) => day !== null)) {
      sortedDays.push(week);
    }

    return sortedDays;
  };

  // Функция для выбора дня и открытия <DayInfo>
  const handleDayClick = (day) => {
    setSelectedDay(day);
  };

  return (
    <div className="calendar">
      {Object.keys(groupedByMonth).map((month) => (
        <div key={month} className="month">
          <h3>{month}</h3>
          <div className="days-of-week">
            {daysOfWeek.map((dayName, index) => (
              <div key={index} className="day-name">{dayName}</div>
            ))}
          </div>
          <div className="weeks-grid">
            {groupedByMonth[month].map((week, weekIndex) => (
              <div key={weekIndex} className="week">
                {week.map((day, dayIndex) => {
                  if (!day) {
                    return <div key={dayIndex} className="empty-day"></div>; // Пустой день для заполнения сетки
                  }
  
                  const date = new Date(day.dayOfYear).getDate();
                  const dayClass = day.events.length > 0
                   ? 'day day-with-events':
                      day.isHoliday
                    ? 'day holiday'
                    : day.shortened
                    ? 'day shortened' // Добавляем класс для дней с событиями
                    : 'day';
  
                  return (
                    <div
                      key={day.dayOfYear}
                      className={dayClass}
                      onClick={() => handleDayClick(day)}
                    >
                      {date}
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        </div>
      ))}
  
      {/* Отображение информации о выбранном дне */}
      {selectedDay !== null && <DayInfo dayData={selectedDay} onClose={() => setSelectedDay(null)} />}
    </div>
  );
};

export default Calendar;
