// src/api/TransactionRequest.js
import axios from 'axios';

// URL вашего бекенда
const API_URL = 'https://free.moscow/backend/transaction';

/**
 * Выполняет POST-запрос для создания новой транзакции.
 * @param {Object} request - Объект запроса с данными транзакции.
 * @param {Function} updateContext - Функция для обновления контекста после успешного запроса.
 * @returns {Promise} - Промис, разрешаемый с ответом от сервера.
 */
export const createTransaction = async (request) => {
  try {
    const response = await axios.post(API_URL, request, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      return response.data
    }

    return response.data; // Возвращаем данные с сервера для дальнейшей обработки
  } catch (error) {
    console.error('Ошибка при создании транзакции:', error);
    throw error; // Пробрасываем ошибку, чтобы можно было её обработать выше
  }
};
