import React from 'react';
import './style/Account.css'; // Для стилизации (ниже опишу)

const Account = ({ account, onClick }) => {
  // Получаем валюту (может быть частью denomination, добавь при необходимости)
  const currency = '₽'; // Задай валюту, если она известна (например, из конфигурации или данных)

  return (
    <div className="account" onClick={() => onClick(account)}>
      <div className="account-info">
        <h2 className="account-name">{account.name || 'Счёт без названия'}</h2>
        <p className="account-description">{account.description || 'Без описания'}</p>
      </div>
      <div className="account-balance">
        <h2>{account.denomination.double}</h2>
        <span>{currency}</span>
      </div>
    </div>
  );
};

export default Account;
