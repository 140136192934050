import React, { createContext, useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';

// Создаем контекст
export const DayInfoContext = createContext();

// Компонент провайдера
export const DayInfoProvider = ({ children }) => {

  const fetchDayInfo = async () => {
    try {
      const response = await axios.get('https://free.moscow/backend/stats/info');
      setDayInfo(response.data);
    } catch (error) {
      console.error("Failed to fetch DayInfo", error);
    }
  };

  const [dayInfo, setDayInfo] = useState([]); // Состояние для списка счетов
  useEffect(()=>{
    fetchDayInfo()
  }, [])

  return (
    <DayInfoContext.Provider value={ dayInfo }>
      {children}
    </DayInfoContext.Provider>
  );
};


