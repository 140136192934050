import React, { useState, useEffect } from 'react';
import './style/SpendingList.css'; // Подключим стили
import { categoryColors } from '../../constants/constants';
import categoryService from '../../constants/constants';
import axios from 'axios';

const SpendingList = ({ }) => {
    const [spendingData, setSpendingData] = useState(null);
    const [isLoaded, setLoaded] = useState(false)

    useEffect(()=>{
        if(spendingData === null){
            axios.get("https://free.moscow/backend/stats").then((res)=>{
                setSpendingData(res.data.spending)
                setLoaded(true)
            })
        }
    }, [isLoaded])
  
  // Функция для расчета цвета прогресс-бара
  const getProgressColor = (percentage) => {
    let red = 255;
    let green = 255;
    if(percentage<=50)
      red = Math.min(255, Math.floor(255 * (percentage / 100))); // Чем ближе к лимиту, тем более красный
    else
      green = Math.min(255, Math.floor(255 * ((100 - percentage) / 100))); // Чем дальше от лимита, тем более зеленый
    return `rgba(${red}, ${green}, 0, 0.6)`; // Цвет в формате RGB
  };
if(isLoaded){
  return (
    <div className="spending-list">
        <h2>Траты</h2>
      {Object.keys(spendingData).map((category) => {
        const spending = spendingData[category].spending;
        const limit = spendingData[category].limit;
        const percentage = (spending / limit) * 100;
        const categoryColor = categoryColors[category] || '#000'; // Цвет категории или черный по умолчанию
        if(limit !== 0){
        return (
          <div key={category} className="spending-item">
            <div className="spending-category">
              <div className="category-color-indicator" style={{ backgroundColor: categoryColor }}></div>
              {categoryService.getCategoryByServiceName(category)}
            </div>
            <div className="progress-bar-container">
              <div
                className="progress-bar"
                style={{
                  width: `${percentage}%`,
                  backgroundColor: getProgressColor(percentage),
                }}
              />
            </div>
            <div className="spending-info">
              {spending}₽ / {limit}₽
            </div>
          </div>
        )};
      })}
    </div>
  );
}
else{
    return(
        null
    );
}
};

export default SpendingList;
