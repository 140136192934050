import React, { useState } from 'react';
import Transaction from './Transaction';
import TransactionPopup from './TransactionPopup'; // Подключаем новый компонент для поп-апа
import '../style/TransactionList.css';
import axios from 'axios'; // Для отправки HTTP-запросо
import categoryService from '../../../constants/constants';
// Функции для форматирования и сортировки дат (оставляем без изменений)
const formatDate = (date) => {
  const today = new Date();
  const transactionDate = new Date(date);

  if (transactionDate.toDateString() === today.toDateString()) {
    return 'Сегодня';
  }

  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  if (transactionDate.toDateString() === yesterday.toDateString()) {
    return 'Вчера';
  }

  const options = { day: 'numeric', month: 'short' };
  return transactionDate.toLocaleDateString('ru-RU', options);
};

const getDateObject = (date) => {
  const monthMap = {
    'янв.': 0,
    'фев.': 1,
    'мар.': 2,
    'апр.': 3,
    'мая': 4,
    'июн.': 5,
    'июл.': 6,
    'авг.': 7,
    'сент.': 8,
    'окт.': 9,
    'нояб.': 10,
    'дек.': 11,
  };
  if (date === 'Сегодня') return { date, timestamp: new Date().getTime() };
  if (date === 'Вчера') {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return { date, timestamp: yesterday.getTime() };
  }

  // Если формат даты выглядит как "26 сент.", нужно его парсить
  const [day, month] = date.split(' '); // Разделяем строку на день и месяц
  const currentYear = new Date().getFullYear(); // Текущий год

  if (monthMap[month]) {
    const parsedDate = new Date(currentYear, monthMap[month], day);
    return { date, timestamp: parsedDate.getTime() };
  }

  console.error(`Invalid date format: ${date}`);
  return { date, timestamp: new Date().getTime() }; // Если формат неправильный
};

const groupByDate = (transactions) => {
  return transactions.reduce((groups, transaction) => {
    const date = formatDate(transaction.createdAt);
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(transaction);
    return groups;
  }, {});
};

const sortDates = (dates) => {
  const dateObjects = dates.map(date => getDateObject(date));
  dateObjects.sort((a, b) => {
    if (a.date === 'Сегодня') return -1;
    if (b.date === 'Сегодня') return 1;
    if (a.date === 'Вчера') return -1;
    if (b.date === 'Вчера') return 1;
    if (a.timestamp !== b.timestamp) return b.timestamp - a.timestamp;
    return 0;
  });

  return dateObjects.map(d => d.date);
};

// Основной компонент
const TransactionList = ({ transactions }) => {
  const categoriesPay = ['Продукты', 'Одежда', 'Для дома', 'Коммунальные платежи','Здоровье', 'Транспорт', 'Развлечения', 'Кредиты', 'Подарки', 'Красота', 'Прочее']; // Пример списка категорий для расходов
  const categoriesReplenish = ['Зарплата', 'Подарки' , 'Прочее']; // Пример списка категорий для пополнений

  const [selectedTransaction, setSelectedTransaction] = useState(null); // Состояние выбранной транзакции
  const groupedTransactions = groupByDate(transactions); // Группировка по датам
  const sortedDates = sortDates(Object.keys(groupedTransactions)); // Сортировка по датам

  // Обработчик клика по транзакции (открытие попапа)
  const handleTransactionClick = (transaction) => {
    setSelectedTransaction(transaction);
  };

  // Закрытие попапа
  const handleClosePopup = () => {
    setSelectedTransaction(null);
  };

  // Сохранение изменений в транзакции
  const handleSaveTransaction = async (updatedTransactionData) => {
    if (!selectedTransaction) return;

    try {
      // URL для PUT-запроса
      const url = `https://free.moscow/backend/transaction/${selectedTransaction.transactionId}`;

      // Отправка PUT-запроса с обновленной категорией и суммой
      await axios.put(url, {
        category: categoryService.getServiceNameByCategory(updatedTransactionData.category),
        denomination: updatedTransactionData.denomination
      });

      console.log('Транзакция обновлена успешно');
      // Здесь можно обновить локальный список транзакций, если нужно
    } catch (error) {
      console.error('Ошибка при обновлении транзакции', error);
    } finally {
      // Закрытие попапа
      handleClosePopup();
    }
  };

  const handleDelete = async () => {
    if (!selectedTransaction) return;

    try {
      // URL для PUT-запроса
      const url = `https://free.moscow/backend/transaction/${selectedTransaction.transactionId}`;

      // Отправка PUT-запроса с обновленной категорией и суммой
      await axios.delete(url);

      console.log('Транзакция удалена успешно');
      // Здесь можно обновить локальный список транзакций, если нужно
    } catch (error) {
      console.error('Ошибка при удалении транзакции', error);
    } finally {
      // Закрытие попапа
      handleClosePopup();
    }
  };

  return (
    <div className="transaction-list">
      {sortedDates.map(date => (
        <div key={date} className="transaction-group">
          <h3>{date}</h3>
          {groupedTransactions[date].reverse().map(transaction => (
            <Transaction
              key={transaction.transactionId}
              transaction={transaction}
              onClick={() => handleTransactionClick(transaction)} // Добавляем обработчик клика
            />
          ))}
        </div>
      ))}

      {/* Рендер попапа только если транзакция выбрана */}
      {selectedTransaction && (
        <TransactionPopup
          categories={selectedTransaction.type === "EXPENDITURE" ? categoriesPay : selectedTransaction.type === "TRANSFER"?["Перевод"]:categoriesReplenish}
          transaction={selectedTransaction}
          onClose={handleClosePopup}
          onSave={handleSaveTransaction}
          onDelete={handleDelete}
        />
      )}
    </div>
  );
};

export default TransactionList;
