// categoryService.js

// Константа-словарь категорий
const categoryDictionary = {
    'Продукты': 'GROCERIES',
    'Одежда' : 'CLOTHING',
    'Для дома': 'HOUSEHOLD',
    'Здоровье': 'HEALTH',
    'Коммунальные платежи': 'UTILITIES',
    'Транспорт': 'TRANSPORT',
    'Развлечения': 'ENTERTAINMENT',
    'Кредиты': 'LOANS',
    'Подарки': 'GIFTS',
    'Зарплата': 'WAGES',
    'Красота': 'BEAUTY',
    'Прочее' : 'OTHER',
    'Перевод': 'TRANSFER'
  };

  export const categoryColorsNotService= {
    'Продукты': '#dbeb34',          // Продукты
    'Одежда': '#eb8f34',           // Одежда
    'Для дома': '#00d173',          // Бытовое
    'Здоровье': '#34dbeb',             // Здоровье
    'Коммунальные платежи': '#3455eb',          // Коммунальные платежи
    'Транспорт': '#ff6388',          // Транспорт
    'Развлечения': '#d447ff',       // Развлечения
    'Кредиты': '#eb4034',
    'Подарки': '#4e00ba',
    'Зарплата': '#ebc034',
    'Красота': '#e60ed4',
    'Прочее': '#666666'
  };

  export const categoryColors= {
    'GROCERIES': '#dbeb34',          // Продукты
    'CLOTHING': '#eb8f34',           // Одежда
    'HOUSEHOLD': '#00d173',          // Бытовое
    'HEALTH': '#34dbeb',             // Здоровье
    'UTILITIES': '#3455eb',          // Коммунальные платежи
    'TRANSPORT': '#ff6388',          // Транспорт
    'ENTERTAINMENT': '#d447ff',       // Развлечения
    'LOANS': '#eb4034',
    'GIFTS': '#4e00ba',
    'WAGES': '#ebc034',
    'BEAUTY': '#e60ed4',
    'OTHER': '#666666'
};

  export const getCategoryColor = (category) => {
    return categoryColors[category] || '#666666'; // Цвет по умолчанию
  };
  
  // Сервис для работы с категориями
  const categoryService = {
    // 1. Получить все категории (обычные названия)
    getAllCategories() {
      return Object.keys(categoryDictionary);
    },
  
    // 2. Получить категорию по служебному названию
    getCategoryByServiceName(serviceName) {
      const entries = Object.entries(categoryDictionary);
      const found = entries.find(([, value]) => value === serviceName);
      return found ? found[0] : null; // Вернуть обычное название или null, если не найдено
    },
  
    // 3. Получить служебное название категории по обычному названию
    getServiceNameByCategory(categoryName) {
      return categoryDictionary[categoryName] || null; // Вернуть служебное название или null, если не найдено
    }
  };
  
  // Экспорт сервиса для использования в других компонентах
  export default categoryService;
  