import React from 'react';
import { Link } from 'react-router-dom';
import './style/header.css'; // Подключаем стили

const Header = () => {
  return (
    <header>
      <div className="logo">
        <Link to="/">Мой Логотип</Link>
      </div>
      <nav>
        <ul>
          <li><Link to="/">Главная</Link></li>
          <li><Link to="/calendar">Календарь</Link></li>
          <li><Link to="/job">Работа</Link></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
