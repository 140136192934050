import React, { useState } from 'react';
import './DayInfo.css'; // Стили для поп-ап окна
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const DayInfo = ({ dayData, onClose }) => {
  const [eventName, setEventName] = useState('');
  const [amount, setAmount] = useState('');
  const [transactionType, setTransactionType] = useState('REPLENISHMENT');
  const [recurrence, setRecurrence] = useState('DAILY');
  const navigate = useNavigate();

  const handleSubmit = () => {
    // Обработка добавления нового события
    const newEvent = {
      name: eventName,
      type: transactionType,
      value: amount,
      eventType: recurrence,
      date: dayData.dayOfYear
    };
    
    axios.post("https://free.moscow/backend/events", newEvent).then((res)=>{
        if(res.status===200){
            onClose();
            navigate(0)
        }
    }
    )
  };

  return (
    <div className="popup">
      <div className="popup-content">
        <div className="popup-left">
          <h2>День: {new Date(dayData.dayOfYear).toLocaleDateString()}</h2>
          <ul className="events-list">
            {dayData.events.map((event, index) => (
              <li key={index} className={`event-item ${event.type.toLowerCase()}`}>
                <span className="event-name">{event.name}</span>
                <span className="event-amount">{event.value.unit}₽</span>
              </li>
            ))}
          </ul>
        </div>

        <div className="popup-right">
          <h3>Добавить событие</h3>
          <div className="form-group">
            <label>Название события:</label>
            <input
              type="text"
              value={eventName}
              onChange={e => setEventName(e.target.value)}
              placeholder="Введите название"
            />
          </div>

          <div className="form-group">
            <label>Сумма:</label>
            <input
              type="number"
              value={amount}
              onChange={e => setAmount(e.target.value)}
              placeholder="Введите сумму"
            />
          </div>

          <div className="form-group">
            <label>Тип транзакции:</label>
            <div className="radio-group">
              <label>
                <input
                  type="radio"
                  value="REPLENISHMENT"
                  checked={transactionType === 'REPLENISHMENT'}
                  onChange={() => setTransactionType('REPLENISHMENT')}
                />
                Пополнение
              </label>
              <label>
                <input
                  type="radio"
                  value="EXPENDITURE"
                  checked={transactionType === 'EXPENDITURE'}
                  onChange={() => setTransactionType('EXPENDITURE')}
                />
                Списание
              </label>
            </div>
          </div>

          <div className="form-group">
            <label>Повторение:</label>
            <select value={recurrence} onChange={e => setRecurrence(e.target.value)}>
              <option value="DAILY">Единожды</option>
              <option value="WEEKLY">Каждую неделю</option>
              <option value="MONTHLY">Каждый месяц</option>
            </select>
          </div>

          <button className="submit-button" onClick={handleSubmit}>Добавить</button>
        </div>

        <button className="close-button" onClick={onClose}>X</button>
      </div>
    </div>
  );
};

export default DayInfo;
