import React, { useState, useContext } from 'react';
import Modal from '../../modal/modal';
import TransactionForm from './transactions/TransactionForm';
import './style/AccountDetails.css'; // Для стилизации
import { AccountsContext } from './AccountsContext';
import { createTransaction } from '../TransactionRequest';
import { useNavigate } from 'react-router-dom';

const AccountDetails = ({ account, onClick }) => {
    const [isPayModalOpen, setIsPayModalOpen] = useState(false);
    const [isReplenishModalOpen, setIsReplenishModalOpen] = useState(false);
    const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  
    const [fromAccountId, setFromAccountId] = useState('');
    const [toAccountId, setToAccountId] = useState('');
    const [amount, setAmount] = useState('');
    const navigate = useNavigate();
  
    const accountsContext = useContext(AccountsContext); // Должен содержать список счетов
    if (!accountsContext) {
      console.error('AccountsContext is undefined');
      return null;
    }

    function convertToUnitAndNanos(amount) {
      const unit = Math.floor(amount); // Целая часть (рубли)
      const nanos = Math.round((amount - unit) * 100); // Дробная часть (копейки)
    
      return {
        unit: unit,
        nanos: nanos
      };
    }
  
    const categoriesPay = ['Продукты', 'Одежда', 'Для дома', 'Коммунальные платежи','Здоровье', 'Транспорт', 'Развлечения', 'Кредиты', 'Подарки', 'Красота', 'Прочее']; // Пример списка категорий для расходов
    const categoriesReplenish = ['Зарплата', 'Подарки' , 'Прочее']; // Пример списка категорий для пополнений
  
    const handleConfirm = (data) => {
      let request={

      }
    };

  
    // Функция для подтверждения перевода
    const handleTransferConfirm  = async () => {
      if (!fromAccountId || !toAccountId || !amount) {
        alert('Пожалуйста, заполните все поля.');
        return;
      }
      if (fromAccountId === toAccountId) {
        alert('Счёт списания не может быть одинаков с счётом пополнения.');
        return;
      }
  
      const request = {
        sourceTransactionType: 'TRANSFER',
        category: 'TRANSFER',
        denomination: convertToUnitAndNanos(parseFloat(amount)),
        createdAt: null, // Используем UUID
        sourceUuid: fromAccountId,     // Используем UUID
        participantUuid: toAccountId,
        name: "Перевод между счетами"
      };
    
      let account = await createTransaction(request);
      navigate(`/account/${fromAccountId}`, { state: { account } });
      setIsTransferModalOpen(false); // Закрыть модальное окно после подтверждения
    };
  
    return (
      <div className="account" onClick={() => onClick(account.id)}>
        <div className="account-info">
          <h2 className="account-name">{account.name || 'Счёт без названия'}</h2>
          <h2 className="account-balance">{account.denomination.double}₽</h2>
        </div>
        <div className="button-panel">
          <div className="button button-icon1" onClick={() => setIsPayModalOpen(true)}>
            <div className="icon icon-pay"></div> {/* Иконка */}
            <p>Оплатить</p>
          </div>
          <div className="button button-icon2" onClick={() => setIsReplenishModalOpen(true)}>
            <div className="icon icon-replenish"></div> {/* Иконка */}
            <p>Пополнить</p>
          </div>
          <div className="button button-icon3" onClick={() => setIsTransferModalOpen(true)}>
            <div className="icon icon-transfer"></div> {/* Иконка */}
            <p>Перевести</p>
          </div>
        </div>
  
        {/* Модальное окно для оплаты */}
        <Modal isOpen={isPayModalOpen} onClose={() => setIsPayModalOpen(false)}>
          <TransactionForm sourceId={account.uuid} type="pay" categories={categoriesPay} onConfirm={handleConfirm}/>
        </Modal>
  
        {/* Модальное окно для пополнения */}
        <Modal isOpen={isReplenishModalOpen} onClose={() => setIsReplenishModalOpen(false)}>
          <TransactionForm sourceId={account.uuid} type="replenish" categories={categoriesReplenish} onConfirm={handleConfirm}/>
        </Modal>
  
        {/* Модальное окно для перевода */}
        <Modal isOpen={isTransferModalOpen} onClose={() => setIsTransferModalOpen(false)}>
          <div className="transfer-form">
            <h2>Перевести</h2>
            <div className="form-group">
              <label htmlFor="from-account">Счёт списания:</label>
              <select
                id="from-account"
                value={fromAccountId}
                onChange={(e) => setFromAccountId(e.target.value)}
              >
                <option value="">Выберите счёт</option>
                {accountsContext.map(acc => (
                  <option key={acc.id} value={acc.uuid}>{acc.name}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="to-account">Счёт пополнения:</label>
              <select
                id="to-account"
                value={toAccountId}
                onChange={(e) => setToAccountId(e.target.value)}
              >
                <option value="">Выберите счёт</option>
                {accountsContext.map(acc => (
                  <option key={acc.id} value={acc.uuid}>{acc.name}</option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="amount">Сумма:</label>
              <input
                id="amount"
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                placeholder="Сумма"
                min="0"
                step="0.01"
              />
            </div>
            <button className="confirm-button" onClick={handleTransferConfirm}>Подтвердить</button>
          </div>
        </Modal>
      </div>
    );
  };
  
  export default AccountDetails;