import React, { useContext } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { DayInfoContext } from './DayInfoWithChartContext';
import { Doughnut } from 'react-chartjs-2';
import './DayInfoWithChart.css';
import { categoryColorsNotService } from '../../constants/constants';
import categoryService from '../../constants/constants';


ChartJS.register(ArcElement, Tooltip, Legend);

const DayInfoWithChart = () => {
// Получаем текущую дату
const today = new Date();

// Массив с названиями месяцев
const months = [
    "января", "февраля", "марта", "апреля", "мая", "июня",
    "июля", "августа", "сентября", "октября", "ноября", "декабря"
];

// Получаем текущий день и месяц
const dayOfMonth = today.getDate();
const month = months[today.getMonth()];  // Индексация месяцев начинается с 0

  const dayInfo = useContext(DayInfoContext)
  try{
  const { dayOfYear, events } = dayInfo.dayStatusDto;
  const spending = dayInfo.spending
  const date = new Date(dayOfYear);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const dayOfWeek = date.toLocaleString('default', { weekday: 'long' });

  let categories = Object.keys(spending)
  const spendingData = categories.map(category => spending[category].spending);
  categories = Object.keys(spending).map(category=>categoryService.getCategoryByServiceName(category));
  const backgroundColors = categories.map(category => categoryColorsNotService[category] || '#000000');
  const chartData = {
    labels: categories,
    datasets: [
      {
        data: spendingData,
        backgroundColor: backgroundColors,
        hoverOffset: 4,
      },
    ],
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0'); // Добавляем 0 перед числом, если оно меньше 10
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0, поэтому +1
    const year = String(date.getFullYear()).slice(-2); // Получаем последние 2 цифры года
  
    return `${day}.${month}.${year}`;
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'right',
        labels: {
          usePointStyle: true, // Включаем отображение точек
          pointStyle: 'circle', // Указываем форму точки (круг)
        }
      },
      tooltip: {
        enabled: false, // Отключаем всплывающие подсказки
      },
    },
  };
  

  const totalExpenditureEvents = events.reduce((sum, event) => sum + (event.type === 'EXPENDITURE' ? event.value.long : 0), 0);
  const totalReplenishmentEvents = events.reduce((sum, event) => sum + (event.type === 'REPLENISHMENT' ? event.value.long : 0), 0)
  return (
    <div className="day-info">
      <div className="info">
        <h2>Привет! Сегодня {dayOfMonth} {month}.</h2>
        <p>{formatDate(date)} будет списано {totalExpenditureEvents}₽</p>
        {totalReplenishmentEvents!==0?<p> {formatDate(date)} придёт {totalReplenishmentEvents}₽</p>:null}
        <p>Всего трат за месяц: {dayInfo.monthExpenditure}₽</p>
        <p>Всего пополнений за месяц: {dayInfo.monthReplenishment}₽</p>
        {dayInfo.remainExpenditure!==0?<p>До конца месяца ещё ожидается трат на {dayInfo.remainExpenditure}₽</p>:null}
        {dayInfo.remainReplenishment!==0?<p>До конца месяца ещё ожидается пополнений на {dayInfo.remainReplenishment}₽</p>:null}
        <p>Остаток баланса на всех счетах: {dayInfo.remainAccountsBalance}₽</p>
      </div>
      <div className="chart">
        <Doughnut data={chartData}  options={options}/>
      </div>
    </div>
  );
}catch{

}};

export default DayInfoWithChart;
