import React, { useState } from 'react';
import '../style/TransactionForm.css'; // Импортируем стили для формы
import { createTransaction } from '../../TransactionRequest';
import CategoryService from '../../../constants/constants';
import { useNavigate } from 'react-router-dom';

const TransactionForm = ({ sourceId, type, categories, onConfirm }) => {
  const [name, setName] = useState('');
  const [amount, setAmount] = useState('');
  const [category, setCategory] = useState(categories[0] || '');
  const navigate = useNavigate();

  // Функция для конвертации суммы в unit и nanos
  function convertToUnitAndNanos(amount) {
    const unit = Math.floor(amount); // Целая часть (рубли)
    const nanos = Math.round((amount - unit) * 100); // Дробная часть (копейки)
  
    return {
      unit: unit,
      nanos: nanos
    };
  }

  const handleConfirm = async () => {
    if (!name || !amount || !category) {
      alert('Пожалуйста, заполните все поля.');
      return;
    }

    // Подтверждение транзакции
    onConfirm({ name, amount, category });

    // Формируем объект запроса
    const requestPayload = {
      sourceTransactionType: type === 'pay' ? 'EXPENDITURE' : 'REPLENISHMENT',
      category: CategoryService.getServiceNameByCategory(category),
      denomination: convertToUnitAndNanos(parseFloat(amount)), // Преобразуем строку в число
      sourceUuid: sourceId,
      participantUuid: null,
      name: name
    };

    try {
      // Создаем транзакцию
      let account = await createTransaction(requestPayload);

      // Очищаем поля формы
      setName('');
      setAmount('');
      setCategory(categories[0] || '');
      navigate(`/account/${sourceId}`, { state: { account } });
    } catch (error) {
      console.error('Ошибка при создании транзакции:', error);
      alert('Не удалось создать транзакцию.');
    }
  };

  return (
    <div className="transaction-form">
      <h2>{type === 'pay' ? 'Оплатить' : type === 'replenish' ? 'Пополнить' : 'Перевести'}</h2>
      <div className="form-group">
        <label htmlFor="name">Название:</label>
        <input
          id="name"
          type="text"
          placeholder="Название"
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </div>
      {type !== 'transfer' && (
        <div className="form-group">
          <label htmlFor="amount">Сумма:</label>
          <input
            id="amount"
            type="number"
            placeholder="Сумма"
            value={amount}
            onChange={e => setAmount(e.target.value)}
          />
        </div>
      )}
      <div className="form-group">
        <label htmlFor="category">Категория:</label>
        <select
          id="category"
          value={category}
          onChange={e => setCategory(e.target.value)}
        >
          {categories.map(cat => (
            <option key={cat} value={cat}>{cat}</option>
          ))}
        </select>
      </div>
      <button className="confirm-button" onClick={handleConfirm}>Подтвердить</button>
    </div>
  );
};

export default TransactionForm;
