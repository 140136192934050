import React from 'react';
import { useLocation } from 'react-router-dom'; // Импортируем useLocation
import TransactionList from './transactions/TransactionList';
import AccountDetails from './AccountDetails';
import { AccountsProvider } from './AccountsContext';

const AccountInfo = () => {
  const location = useLocation();
  const account = location.state?.account; // Извлекаем переданные данные из состояния

  if (!account) {
    return <div>Информация о счете недоступна.</div>;
  }

  return (
    <div>
        <AccountsProvider>
        <AccountDetails account={account} onClick={()=>{return;}}/>
        </AccountsProvider>
    <TransactionList transactions={account.transactions} />
    </div>
  );
};

export default AccountInfo;
