import React, { createContext, useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';

// Создаем контекст
export const CalendarContext = createContext();

// Компонент провайдера
export const CalendarProvider = ({ children }) => {

  const fetchCalendar = async () => {
    try {
      const response = await axios.get('https://free.moscow/backend/calendar');
      setCalendar(response.data.days);
    } catch (error) {
      console.error("Failed to fetch calendar", error);
    }
  };

  const [dayData, setCalendar] = useState([]); // Состояние для списка счетов
  useEffect(()=>{
    fetchCalendar()
  }, [])

  return (
    <CalendarContext.Provider value={ dayData }>
      {children}
    </CalendarContext.Provider>
  );
};


