import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom'; // Импортируем useNavigate
import { AccountsContext } from './AccountsContext'; // Убедись, что путь правильный
import Account from './Account';
import './style/AccountsList.css';

const AccountsList = () => {
  const accounts = useContext(AccountsContext);
  const navigate = useNavigate(); // Создаем экземпляр navigate

  // Проверяем, что accounts — массив
  if (!Array.isArray(accounts) || accounts.length === 0) {
    return <div>Нет доступных счетов...</div>;
  }

  const handleAccountClick = (account) => {
    navigate(`/account/${account.uuid}`, { state: { account } }); // Передаем счет в состояние
  };

  return (
    <div className="accounts-list">
      <h2>Счета</h2>
      {accounts.map((account) => (
        <Account 
          key={account.id}
          account={account}
          onClick={() => handleAccountClick(account)} // Используем обработчик клика
        />
      ))}
    </div>
  );
};

export default AccountsList;
