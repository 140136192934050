import React from 'react';
import '../style/Transaction.css'; 
import categoryService from '../../../constants/constants';
import { getCategoryColor } from '../../../constants/constants';

const Transaction = ({ transaction, onClick }) => {
  const { type, category, denomination, name } = transaction;
  const iconClass = 'icon ' + transaction.category.toLowerCase();

  return (
    <div
      className={`transaction ${type === 'REPLENISHMENT' ? 'replenishment' : 'spending'}`}
      onClick={onClick} // Добавляем обработчик клика
    >
      <div
        className="transaction-icon"
        style={{ backgroundColor: getCategoryColor(category) }}
      >
        <div className={iconClass}></div>
      </div>
      <div className="transaction-details">
        <div className="transaction-description">
          <span>{name}</span>
          <p>{categoryService.getCategoryByServiceName(category)}</p>
        </div>
        <div className="transaction-amount">
          <h2>{denomination.double}₽</h2>
        </div>
      </div>
    </div>
  );
};

export default Transaction;
