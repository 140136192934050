import React, { createContext, useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';

// Создаем контекст
export const AccountsContext = createContext();

// Компонент провайдера
export const AccountsProvider = ({ children }) => {

  const fetchAccounts = async () => {
    try {
      const response = await axios.get('https://free.moscow/backend/account');
      setAccounts(response.data);
    } catch (error) {
      console.error("Failed to fetch accounts", error);
    }
  };

  const [accounts, setAccounts] = useState([]); // Состояние для списка счетов
  useEffect(()=>{
    fetchAccounts()
  }, [])

  return (
    <AccountsContext.Provider value={ accounts }>
      {children}
    </AccountsContext.Provider>
  );
};


